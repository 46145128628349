import React from 'react'
import LandingPageAdvertorial from '../../../templates/landing-page-advertorial'

import content from '../../../data/pages/article/debt-solution-program/content.json'

const ArticleAdvertorialLP = () => (
  <LandingPageAdvertorial content={content} />
)

export default ArticleAdvertorialLP
