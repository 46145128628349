import React, { Component } from 'react'
import { DateTime } from 'luxon'

import Title from '../atoms/title'
import Link from '../atoms/link'
import BodyCopy from '../atoms/body-copy'
import Lists from '../atoms/lists'
import Button from '../atoms/button'
// import KeyFeatures from '../molecules/key-features'
import Trustpilot from '../molecules/trustpilot'


import hero from '../../images/hero-advertorial.png';
import map from '../../images/map_green.png';

class Advertorial extends Component {
  constructor(props) {
      super(props);
      this.state = {
          content: this.props.content,
          showMap: this.props.showMap,
          showButton: this.props.showButton,
          showQuestionnaire: this.props.showQuestionnaire,
          UTMparams: ''
      }

      this.grabUTMparams = this.grabUTMparams.bind(this);
      this.addUTMparamsToAnchorTags = this.addUTMparamsToAnchorTags.bind(this);
  }

  componentDidMount() {
    this.grabUTMparams();
  }
  
  grabUTMparams() {
    this.setState({
      UTMparams: window.location.search,
    });
    setTimeout(() => {
      this.addUTMparamsToAnchorTags();
    }, 250);
  }

  addUTMparamsToAnchorTags() {
    let advertorial = document.querySelector('.co-advertorial');
    let anchors = advertorial.getElementsByTagName('a');
    if(anchors) {
      for (let index = 0; index < anchors.length; index++) {
        const element = anchors[index];

        if(!element.classList.contains('co-advertorial__image')) {
          element.href = element.href + this.state.UTMparams;
        }
      }
    }
  }

  renderTrustPilot(content) {
    let trustPilots = [];

		content.map((item, index) => {
      trustPilots.push(
        <Trustpilot
          content={item}
          type='advertorial'
        />
      );
		});

		return trustPilots;
  }

  render() {      
      return (
        <div className='co-advertorial'>
          <div className='l-section l-section--small'>
            <div className='co-advertorial__info'>
              <span className='co-advertorial__info-type'>{this.state.content.info.type}</span>
              <div className='co-advertorial__info-wrap'>
                <span className='co-advertorial__info-category'>{this.state.content.info.category}</span>
                <span className='co-advertorial__info-date'>{DateTime.now().setZone('America/New_York').minus({weeks:1}).endOf('day').toLocaleString(DateTime.DATE_FULL)}</span>
              </div>
            </div>
          </div>

          <div className='l-section'>
            <Title
                type='h1'
                title={this.state.content.section1.title1}
                classNames='co-advertorial__title'
            />
            <Link
              id={this.state.content.section1.image.id}
              url={this.state.content.section1.image.linkUrl + this.state.UTMparams}
              target={this.state.content.section1.image.target}
              align='center'
              classNames='co-advertorial__image l-spacing__m-tb--24'
              type="image"
              external
            >
              <img src={hero} alt={this.state.content.section1.image.alt} />
            </Link>
          </div>

          <div className='l-section'>
            <div className='l-grid__row'>
              <div className='l-grid__col'>
                <div className='l-container l-container--col'>
                  <BodyCopy
                    copy={this.state.content.section2.bodyCopy}
                    classNames='co-advertorial__copy'
                  />
                  {/* <Lists
                    items={this.state.content.section2.list}
                    classNames='co-advertorial__list'
                  /> */}
                  <Title
                    type='h2'
                    title={this.state.content.section2.title1}
                    classNames='co-advertorial__title co-advertorial__title--1 l-spacing__m-b--32 l-spacing__m-t--32'
                  />
                  <BodyCopy
                    copy={this.state.content.section2.bodyCopy1}
                    classNames='co-advertorial__copy l-spacing__p-b--8'
                  />
                  {/* {this.renderTrustPilot(this.state.content.section2.trustContent)} */}
                </div>
              </div>
            </div>
          </div>

          <div className='l-section l-section--t l-section--pop'>
            <div className='l-container l-container--pop'>
              <Title
                type='h3'
                title={this.state.content.section3.title1}
                align='left'
                color='dark'
                weight='bold'
                classNames='co-advertorial__title'
              />
              <BodyCopy
                copy={this.state.content.section3.bodyCopy}
                classNames='co-advertorial__copy'
              />

              <Lists
                items={this.state.content.section3.list}
                classNames='co-advertorial__list'
              />

              <Link
                id={this.state.content.section3.image.id}
                url={this.state.content.section3.image.linkUrl + this.state.UTMparams}
                target={this.state.content.section3.image.target}
                align='center'
                classNames='co-advertorial__image l-spacing__m-tb--40'
                type="image"
                external
              >
                <img src={map} alt={this.state.content.section3.image.alt} />
              </Link>
            </div>
          </div>

          {/* <div className='l-section'>
            <div className='l-container'>
              <Button
                classNames='co-advertorial__button'
                type='primary'
                label={this.state.content.button.label}
                id={this.state.content.button.id}
                onClick={(e) => window.location.href = this.state.content.button.url}
                large
              />
            </div>
          </div> */}

          {/* <div className='l-section'>
            <div className='l-container l-container--inner'>
              <KeyFeatures
                features={this.state.content.keyFeatures}
              />
            </div>
          </div> */}

        </div>
      );
  }
}

export default Advertorial
